import { Chat } from 'modules/ui';
import { useUpdateTrainingProgramChat } from '../../hooks';
import { Container, Page } from 'modules/layout';
import { useLogMessages } from 'modules/logger/hooks/useLogMessages';

export default function UpdateTrainingProgramChat() {
  const { messages, handleSendMessage } = useUpdateTrainingProgramChat();

  useLogMessages('update-training-program', messages);

  return (
    <Page>
      <Container className="flex-1">
        <Chat messages={messages} onSendMessage={handleSendMessage} />
      </Container>
    </Page>
  );
}
