import { useGetUserQuery } from 'modules/user';
import cn from 'classnames';

interface DayPlanProps {
  className?: string;
  dayOfWeek: string;
  title?: string;
}

function DayPlan({ title, dayOfWeek, className }: DayPlanProps) {
  const { data } = useGetUserQuery('home');
  return (
    <div className={cn(className, 'w-full')}>
      <p>{title || dayOfWeek}</p>
      <div className="mt-2 px-2 py-3 border border-black">
        {data?.trainingProgram?.[dayOfWeek]?.map(
          (item: { name: string; description: string }) => (
            <p key={item.name} className="mb-2 last:mb-0">
              <b>{item.name}:</b> {item.description || 'do nothing :)'}
            </p>
          )
        )}
      </div>
    </div>
  );
}

export default DayPlan;
