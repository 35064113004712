import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'modules/core';

export interface OnboardingState {
  devices: string[];
  sharedData: string[];
}

const initialState: OnboardingState = {
  devices: [],
  sharedData: [],
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setDevices: (state, action: PayloadAction<string[]>) => {
      state.devices = action.payload.map((device) => device.toLowerCase());
    },
    setSharedData: (state, action: PayloadAction<string[]>) => {
      state.sharedData = action.payload;
    },
  },
});

export const { setSharedData, setDevices } = onboardingSlice.actions;

export const selectDevices = (state: RootState) => state.onboarding.devices;
export const selectSharedData = (state: RootState) =>
  state.onboarding.sharedData;

export default onboardingSlice.reducer;
