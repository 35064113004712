import { Container, Page } from 'modules/layout';
import Title from '../Title';
import ViewModes from '../ViewModes';
import { Outlet, useLocation } from 'react-router-dom';
import { useMemo } from 'react';

function OnboardingPage() {
  const { pathname } = useLocation();

  const pageMessage = useMemo(() => {
    if (pathname.includes('buttons/devices')) {
      return 'We will import data from your devices, please select what do you want to connect. On the next page you can define what data do you want to share.';
    }
    if (pathname.includes('buttons/share-data')) {
      return 'We respect your privacy, tell us what kind of health data do you want to share from the devices?';
    }
    if (pathname.includes('summary')) {
      return "Ok, based on our conversation I'll connect to these devices and collect the health data the you approved.";
    }
    return null;
  }, [pathname]);

  return (
    <Page>
      <Container className="flex flex-col flex-1">
        <div className="flex items-center justify-between">
          <Title />
          <ViewModes className="relative -right-[7px]" />
        </div>
        {pageMessage && <p className="mt-2.5 text-sm">{pageMessage}</p>}
        <div className="flex-1 basis-0 h-full pt-2.5">
          <Outlet />
        </div>
      </Container>
    </Page>
  );
}

export default OnboardingPage;
