import { useEffect } from 'react';
import { Message } from 'types';
import { useAppSelector } from 'modules/core';
import {
  FINISHING_MESSAGE,
  finishingMessages,
  initialMessages,
} from '../constants';
import { useOpenAI, useInjectedMessages } from 'modules/openai';
import { selectUser } from 'modules/user';

export function usePersonalInfoChat() {
  const openai = useOpenAI();
  const user = useAppSelector(selectUser);
  const [messages, setMessages] = useInjectedMessages(initialMessages, {
    name: user.name,
  });

  const handleSendMessage = (message: Message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  useEffect(
    function manageChat() {
      async function run() {
        if (
          !openai ||
          messages.length === 0 ||
          messages.at(-1)?.role === 'assistant'
        )
          return;

        const completion = await openai.createChatCompletion({
          model: 'gpt-3.5-turbo',
          temperature: 0.1,
          messages: messages.map(({ hidden, ...message }) => message),
        });
        const responseMessage = completion.data.choices[0].message as Message;
        const hasFinishingMessage =
          responseMessage.content.includes(FINISHING_MESSAGE);

        setMessages((prevMessages) => [
          ...prevMessages,
          { ...responseMessage, hidden: hasFinishingMessage },
        ]);

        if (!hasFinishingMessage) return;

        const finishingCompletion = await openai?.createChatCompletion({
          model: 'gpt-3.5-turbo',
          temperature: 0.1,
          messages: [
            ...messages.map(({ hidden, ...message }) => message),
            responseMessage,
            ...finishingMessages.map(({ hidden, ...message }) => message),
          ],
        });

        const result = JSON.parse(
          finishingCompletion?.data.choices[0].message?.content.replace(
            'EXPORT',
            ''
          ) || '[]'
        );

        console.log(result);
        // dispatch(setDevices(result.devices));
        // try {
        //   await addDevices(
        //     result.devices.map((device: string) => ({
        //       name: device,
        //       data: {},
        //     }))
        //   );
        //   navigate('/onboarding/chat/share-data');
        // } catch (error) {
        //   alert('Error adding devices');
        // }
      }

      run();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openai, messages]
  );

  return { messages, handleSendMessage } as const;
}
