import { Message } from 'types';
import cn from 'classnames';

interface MessageItemProps {
  className?: string;
  data: Message;
}

function MessageItem({ className, data }: MessageItemProps) {
  return (
    <div
      className={cn(className, 'w-full pb-3 px-3', {
        'bg-blue-200': data.role === 'user',
        'bg-gray-200': data.role === 'assistant',
      })}
    >
      <span className="text-[#767677] text-xs">
        {data.role === 'assistant' ? 'Adidas' : 'You'}
      </span>
      <p className="whitespace-pre-wrap">{data.content}</p>
    </div>
  );
}

export default MessageItem;
