import { useAppSelector } from 'modules/core';
import { selectUser } from 'modules/user';

function Title() {
  const { name } = useAppSelector(selectUser);
  return (
    <h2 className="font-['AdineuePRO'] font-bold uppercase text-2xl tracking-[1px]">
      Hello {name}
    </h2>
  );
}

export default Title;
