import { Message } from 'types';
import { useEffect, useState } from 'react';
import { injectVariablesToPrompts } from 'modules/openai';

export function useInjectedMessages(
  initialMessages: Message[],
  variables: Record<string, any>
) {
  const [messages, setMessages] = useState<Message[]>([]);

  function areVariablesReady() {
    return Object.values(variables).every((value) => !!value);
  }

  useEffect(
    function initInjectedMessages() {
      if (areVariablesReady() && messages.length === 0) {
        setMessages(injectVariablesToPrompts(initialMessages, variables));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variables]
  );

  return [messages, setMessages] as const;
}
