import onboardingReducer from 'modules/onboarding/services';
import uiReducer from 'modules/ui/services/slice';
import userReducer, { userApi } from 'modules/user/services';
import { authApi } from 'modules/auth/services/authApi';
import { devicesApi } from 'modules/onboarding/modules/devices';
import { loggerApi } from 'modules/logger/services/loggerApi';

export const reducer = {
  onboarding: onboardingReducer,
  user: userReducer,
  ui: uiReducer,
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [devicesApi.reducerPath]: devicesApi.reducer,
  [loggerApi.reducerPath]: loggerApi.reducer,
};
