import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAccessToken } from 'modules/auth';

export const devicesApi = createApi({
  reducerPath: 'devicesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: async (headers) => {
      const token = await getAccessToken();
      if (token) headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    addDevices: build.mutation({
      query: (devices) => ({
        url: `devices`,
        method: 'POST',
        body: {
          devices,
        },
      }),
    }),
    getDevices: build.query({
      query: () => 'devices',
    }),
  }),
});

export const { useAddDevicesMutation, useGetDevicesQuery } = devicesApi;
