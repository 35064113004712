import { devices } from '../../../devices/constants';
import { Checkbox } from 'modules/form';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Button } from 'modules/ui';
import { useNavigate } from 'react-router-dom';
import { useGetUserQuery, useUpdateUserMutation } from 'modules/user';
import { shareDataOptions } from '../../../share-data/constants';
import { useGetDevicesQuery } from '../../../devices';
function Summary() {
  const navigate = useNavigate();
  const [updateUser] = useUpdateUserMutation();
  const { data: userData, refetch: refetchUser } = useGetUserQuery(null);
  const { data: devicesData, refetch: refetchDevices } =
    useGetDevicesQuery(null);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const filteredDevices = useMemo(() => {
    return devices.filter((device) =>
      devicesData?.some(({ name }: { name: string }) =>
        name.toLowerCase().includes(device.value)
      )
    );
  }, [devicesData]);

  const allChecked = selectedOptions.length === shareDataOptions.length;

  function handleAllCheckboxChange({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) {
    if (!checked) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions(shareDataOptions.map((option) => option.label));
    }
  }

  function handleCheckboxChange(value: string) {
    return function (e: ChangeEvent<HTMLInputElement>) {
      //e.preventDefault();
      setSelectedOptions((prev) => {
        if (
          prev.some(
            (selected) => selected.toLowerCase() === value.toLowerCase()
          )
        ) {
          return prev.filter(
            (item) => item.toLowerCase() !== value.toLowerCase()
          );
        }
        return [...prev, value];
      });
    };
  }

  async function handleSave() {
    try {
      await updateUser({
        sharedData: selectedOptions,
      }).unwrap();
      navigate('/onboarding/chat/goals');
    } catch (e) {
      alert('Error while updating user');
    }
  }

  useEffect(function init() {
    refetchUser();
    refetchDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    function initSelectedOptions() {
      if (userData) {
        setSelectedOptions(userData.sharedData);
      }
    },
    [userData]
  );

  return (
    <div className="w-full h-full overflow-hidden flex flex-col pt-4">
      <div className="overflow-y-auto flex-1 basis-0">
        {filteredDevices.map((device) => (
          <div
            key={device.value}
            className="w-full mb-4 px-4 pr-6 h-16 flex items-center justify-between"
          >
            <img
              className="w-full max-w-[100px]"
              src={`/assets/devices/${device.src}`}
              alt={device.value}
            />
          </div>
        ))}
        <div className="mb-4">
          <Checkbox
            checked={allChecked}
            label="Share all data"
            onChange={handleAllCheckboxChange}
          />
        </div>
        {shareDataOptions.map((option) => (
          <div key={option.label} className="mb-4 last:mb-0">
            <Checkbox
              checked={selectedOptions.some(
                (selected) =>
                  selected.toLowerCase() === option.label.toLowerCase()
              )}
              label={option.label}
              onChange={handleCheckboxChange(option.label)}
            />
          </div>
        ))}
      </div>
      <div className="flex w-full mt-4">
        <Button onClick={handleSave} className="w-full">
          Next
        </Button>
      </div>
    </div>
  );
}

export default Summary;
