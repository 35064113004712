import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/fonts.scss';
import './styles/index.css';
import App from 'modules/application';
import reportWebVitals from './reportWebVitals';
import Core from './modules/core';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Core>
    <App />
  </Core>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
