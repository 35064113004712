import { Container, Page } from 'modules/layout';
import { Button, Title } from 'modules/ui';
import { useGetUserQuery, useUpdateUserMutation } from 'modules/user';
import { useEffect } from 'react';
import { DayPlan } from '../../../../components';
import { getDayOfWeek } from '../../../../utils';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import localforage from 'localforage';

function HomePage() {
  const navigate = useNavigate();
  const { data, refetch } = useGetUserQuery('home');
  const [updateUser] = useUpdateUserMutation();

  async function handleReset() {
    try {
      await updateUser({
        onboardingFinished: false,
        preferences: [],
        sharedData: [],
        goals: [],
        conditions: [],
        trainingProgram: null,
      }).unwrap();
      localStorage.clear();
      await localforage.clear();
      navigate('/');
    } catch (error) {
      alert('Error while updating user');
    }
  }

  useEffect(function init() {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //if (isLoading || isFetching) return null;

  if (data && !data.onboardingFinished) return <Navigate to={'/onboarding'} />;

  return (
    <Page>
      <div className="flex-1">
        <Container>
          <div className="w-full flex items-center justify-between">
            <Title>Hello {data?.name}</Title>
            <Button onClick={handleReset} variant="text">
              reset
            </Button>
          </div>
          <div className="w-full mt-4">
            <DayPlan title="Today's plan" dayOfWeek={getDayOfWeek()} />
          </div>
        </Container>
      </div>
      <div className="w-full">
        <Container className="flex pt-4">
          <Link className="w-full" to={'/weekly-program'}>
            <Button>Week</Button>
          </Link>
          <Button className="ml-4">Notifications</Button>
        </Container>
      </div>
    </Page>
  );
}

export default HomePage;
