import { configureStore } from '@reduxjs/toolkit';
import { reducer } from '../services/reducer';
import { authApi } from 'modules/auth';
import { userApi } from 'modules/user';
import { devicesApi } from 'modules/onboarding/modules/devices';
import { loggerApi } from 'modules/logger/services/loggerApi';

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      userApi.middleware,
      devicesApi.middleware,
      loggerApi.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
