import { useEffect, useRef } from 'react';
import { Message } from 'types';
import { useNavigate } from 'react-router-dom';
import { finishingMessages, initialMessages } from '../constants';
import {
  useOpenAI,
  useInjectedMessages,
  extractJSONFromString,
} from 'modules/openai';
import { useGetUserQuery, useUpdateUserMutation } from 'modules/user';
import { useAppDispatch } from 'modules/core';
import { setLoading } from '../../../../../../ui/services';

export function useTrainingProgramChat() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const openai = useOpenAI();
  const [updateUser] = useUpdateUserMutation();
  const { data: user, refetch } = useGetUserQuery('');
  const [messages, setMessages] = useInjectedMessages(initialMessages, {
    name: user?.name,
    jsonData: JSON.stringify({
      name: user?.name,
      conditions: user?.conditions,
      preferences: user?.preferences,
      goals: user?.goals,
    }),
  });
  const initialRequestSent = useRef(false);

  const handleSendMessage = (message: Message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  useEffect(
    function manageChat() {
      async function run() {
        if (!openai || messages.length === 0 || messages.length === 2) return;

        initialRequestSent.current = true;

        const completion = await openai.createChatCompletion({
          model: 'gpt-3.5-turbo',
          temperature: 0.1,
          messages: messages.map(({ hidden, ...message }) => message),
        });
        const responseMessage = completion.data.choices[0].message as Message;

        setMessages((prevMessages) => [
          ...prevMessages,
          { ...responseMessage, hidden: false },
        ]);

        const finishingCompletion = await openai?.createChatCompletion({
          model: 'gpt-3.5-turbo',
          temperature: 0.1,
          messages: [
            ...messages.map(({ hidden, ...message }) => message),
            responseMessage,
            ...finishingMessages.map(({ hidden, ...message }) => message),
          ],
        });

        const result = extractJSONFromString(
          finishingCompletion?.data.choices[0].message?.content || ''
        );

        if (!result) {
          alert(finishingCompletion?.data.choices[0].message?.content);
          return;
        }

        try {
          await updateUser({
            trainingProgram: result,
            onboardingFinished: true,
          }).unwrap();
          await navigate('/home');
        } catch (e) {
          alert('Error while updating user');
        } finally {
          dispatch(setLoading(null));
        }
      }

      run();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openai, messages]
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { messages, handleSendMessage } as const;
}
