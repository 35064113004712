import { Outlet } from 'react-router-dom';
import Header from '../Header';
import FullscreenLoading from 'modules/ui/components/FullscreenLoading';

function Layout() {
  return (
    <div className="w-full fixed w-full h-full top-0 left-0 flex flex-col overflow-hidden">
      <Header />
      <Outlet />
      <FullscreenLoading />
    </div>
  );
}

export default Layout;
