import { createContext, ReactNode, useEffect, useState } from 'react';
import { Configuration, OpenAIApi } from 'openai';

export const OpenAIContext = createContext<OpenAIApi | null>(null);

interface OpenAIProps {
  children: ReactNode;
}
export function OpenAI({ children }: OpenAIProps) {
  const [openai, setOpenAI] = useState<OpenAIApi | null>(null);

  useEffect(function init() {
    const configuration = new Configuration({
      apiKey: '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',
    });

    setOpenAI(new OpenAIApi(configuration));
  }, []);

  return (
    <OpenAIContext.Provider value={openai}>{children}</OpenAIContext.Provider>
  );
}
