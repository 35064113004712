import { Routes, Route, Navigate } from 'react-router-dom';
import { LoginPage, DisclaimerPage } from 'modules/auth';
import { Layout } from 'modules/layout';
import {
  OnboardingPage,
  //DevicesList,
  PersonalInfoChat,
  ShareDataChat,
  Summary,
  GoalsChat,
  TrainingProgramChat,
} from 'modules/onboarding';
import { DevicesChat } from 'modules/onboarding/modules/devices/modules/chat/components/DevicesChat/DevicesChat';
import { DevicesList } from 'modules/onboarding/modules/devices/modules/buttons-mode/components/DevicesList/DevicesList';
import {
  HomePage,
  UpdateTrainingProgramChat,
  WeeklyProgramPage,
} from 'modules/home';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<DisclaimerPage />} />
        <Route path="auth" element={<LoginPage />} />
        <Route path="home" element={<HomePage />} />
        <Route path="weekly-program" element={<WeeklyProgramPage />} />
        <Route
          path="update-training-program"
          element={<UpdateTrainingProgramChat />}
        />
        <Route path="onboarding" element={<OnboardingPage />}>
          <Route index element={<Navigate to="chat/devices" />} />
          <Route path="buttons/devices" element={<DevicesList />} />
          <Route path="chat/devices" element={<DevicesChat />} />
          <Route path="chat/share-data" element={<ShareDataChat />} />
          <Route path="chat/personal-info" element={<PersonalInfoChat />} />
          <Route path="summary" element={<Summary />} />
          <Route path="chat/goals" element={<GoalsChat />} />
          <Route
            path="chat/training-program"
            element={<TrainingProgramChat />}
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
