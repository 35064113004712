import cn from 'classnames';
import type { Option } from 'types';
import { Link, useLocation } from 'react-router-dom';

interface ViewModesProps {
  className?: string;
}

const options: Option[] = [
  {
    value: 'chat',
    label: 'Chat',
  },
  {
    value: 'buttons',
    label: 'Buttons',
  },
];

function ViewModes({ className }: ViewModesProps) {
  const location = useLocation();
  return (
    <div className={cn(className, 'flex')}>
      {options.map((option) => (
        <Link key={option.value} to={`/onboarding/${option.value}/devices`}>
          <button
            className={cn('flex uppercase px-2 text-xs tracking-[2px]', {
              'text-[#767677]': !location.pathname.includes(option.value),
              'font-bold': location.pathname.includes(option.value),
            })}
          >
            <span
              className={cn('flex py-1', {
                'border-b-[2px] border-black': location.pathname.includes(
                  option.value
                ),
              })}
            >
              {option.label}
            </span>
          </button>
        </Link>
      ))}
    </div>
  );
}

export default ViewModes;
