import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { useAppSelector } from 'modules/core/modules/redux/hooks';
import Loader from '../Loader';

function FullscreenLoading() {
  const message = useAppSelector((state) => state.ui.fullscreenLoadingMessage);
  const opened = !!message;

  return createPortal(
    <AnimatePresence>
      {opened && (
        <motion.div
          className="fixed inset-0 bg-black flex flex-col items-center justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <p className="text-white uppercase text-sm font-bold tracking-[2px]">
            {message}
          </p>
          {opened && <Loader className="mt-4" />}
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
}

export default FullscreenLoading;
