import { Option } from 'types';

export const shareDataOptions: Option[] = [
  {
    label: 'Basic information',
    value: 'basic_information',
  },
  {
    label: 'Activity',
    value: 'activity',
  },
  {
    label: 'Body measurement',
    value: 'body_measurement',
  },
  {
    label: 'Vital signs',
    value: 'vital_signs',
  },
  {
    label: 'Mobility',
    value: 'mobility',
  },
  {
    label: 'Lab and test results',
    value: 'lab_and_test_results',
  },
  {
    label: 'Mindfulness and sleep',
    value: 'mindfulness_and_sleep',
  },
  {
    label: 'Workouts',
    value: 'workouts',
  },
];
