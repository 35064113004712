import { Message } from 'types';

export const FINISHING_MESSAGE = 'END OF SESSION';
export const initialOnboardingDevicesMessages: Message[] = [
  {
    role: 'system',
    content:
      "You are an AI Fitness Trainer App. Now we are doing the onboarding of the USER and you are setting up the connected smart devices and health data collected from them. These are the guidelines: 1. You cannot talk about anything else; 2. Always ask for permission to connect to the device; 3. Never ask the USER to follow steps and connect the devices manually, you need to do that in the background; 4. Don't answer on behalf of the USER; 5. When the USER answers your all of your questions say: 'END OF SESSION'; 6. When you are asked to format data as json, you are prohibited to say anything like \"Sure, here is the JSON...\", you can only send the json.\n" +
      '\n' +
      'Here is an example, but you can refine your sentences:\n' +
      "'assistant':  Hello {{name}}! Please tell me what devices you use to track your activities?\n" +
      "'USER': I have an Apple Watch and a Smart Scale.\n" +
      "'assistant': Thank you, may I connect to these devices?\n" +
      "'USER': Yes.\n" +
      "'assistant': Do you have any other devices?\n" +
      "'USER': No.\n" +
      "'assistant': END OF SESSION.",
    hidden: true,
  },
  {
    role: 'user',
    content:
      "start the conversation with the user when I say start. The name of the user is '{{name}}'.",
    hidden: true,
  },
  {
    role: 'user',
    content: 'start.',
    hidden: true,
  },
];

export const finishingOnboardingDevicesMessages: Message[] = [
  {
    role: 'user',
    content:
      "Now summarise all device data from the user to this json format and add 'EXPORT' to the beginning of your message.\n" +
      '\n' +
      '{\n' +
      '"devices": [\n' +
      '     "device1",\n' +
      '     "device2",\n' +
      '     "device3"\n' +
      ']\n' +
      '}\n' +
      'in the next message send only the json, nothing else.',
    hidden: true,
  },
];
