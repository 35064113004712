import Container from '../Container'

function Header() {
  return (
    <header className="w-full h-12 bg-black flex items-center">
      <Container className="flex justify-between">
        <img
          className="w-full max-w-[150px]"
          src="/assets/FullLogo_Negative.svg"
          alt="CreativeDock"
        />
        <img
          className="w-full max-w-[40px] invert"
          src="/assets/Adidas_Logo.svg"
          alt="Adidas"
        />
      </Container>
    </header>
  )
}

export default Header
