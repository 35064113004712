import { Chat } from 'modules/ui';
import { useGoalsChat } from '../../hooks';
import { useLogMessages } from 'modules/logger/hooks/useLogMessages';

export default function GoalsChat() {
  const { messages, handleSendMessage } = useGoalsChat();

  useLogMessages('goals', messages);

  return <Chat messages={messages} onSendMessage={handleSendMessage} />;
}
