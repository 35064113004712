import { useEffect, useRef } from 'react';
import { Message } from 'types';
import { useAppDispatch, useAppSelector } from 'modules/core';
import { useNavigate } from 'react-router-dom';
import {
  FINISHING_MESSAGE,
  finishingMessages,
  initialMessages,
} from '../constants';
import {
  useOpenAI,
  useInjectedMessages,
  extractJSONFromString,
} from 'modules/openai';
import { selectUser, useUpdateUserMutation } from 'modules/user';
import { setLoading } from '../../../../../../ui/services';

export function useShareDataChat() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const openai = useOpenAI();
  const [updateUser] = useUpdateUserMutation();
  const user = useAppSelector(selectUser);
  const [messages, setMessages] = useInjectedMessages(initialMessages, {
    name: user.name,
  });
  const initialRequestSent = useRef(false);

  const handleSendMessage = (message: Message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  useEffect(
    function manageChat() {
      async function run() {
        if (
          !openai ||
          messages.length === 0 ||
          (messages.at(-1)?.role === 'assistant' && initialRequestSent.current)
        )
          return;

        initialRequestSent.current = true;

        const completion = await openai.createChatCompletion({
          model: 'gpt-3.5-turbo',
          temperature: 0.1,
          max_tokens: 600,
          messages: messages.map(({ hidden, ...message }) => message),
        });
        const responseMessage = completion.data.choices[0].message as Message;
        const hasFinishingMessage =
          responseMessage.content.includes(FINISHING_MESSAGE);
        const hasJSON = extractJSONFromString(responseMessage.content);

        setMessages((prevMessages) => [
          ...prevMessages,
          { ...responseMessage, hidden: hasFinishingMessage },
        ]);

        if (!hasFinishingMessage && !hasJSON) return;

        dispatch(setLoading('Saving permissions'));

        let result: null | Record<string, any> = hasJSON;

        if (hasFinishingMessage) {
          const finishingCompletion = await openai?.createChatCompletion({
            model: 'gpt-3.5-turbo',
            temperature: 0.1,
            max_tokens: 600,
            messages: [
              ...messages.map(({ hidden, ...message }) => message),
              responseMessage,
              ...finishingMessages.map(({ hidden, ...message }) => message),
            ],
          });

          result = extractJSONFromString(
            finishingCompletion?.data.choices[0].message?.content || ''
          );
        }

        if (!result) {
          alert('Error while parsing JSON, refresh the page and try again');
          dispatch(setLoading(null));
          return;
        }

        try {
          await updateUser({
            sharedData: result?.data_types,
          }).unwrap();
          await navigate('/onboarding/summary');
        } catch (e) {
          alert('Error while updating user');
        } finally {
          dispatch(setLoading(null));
        }
      }

      run();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openai, messages]
  );

  return { messages, handleSendMessage } as const;
}
