import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { TextField } from 'modules/form';
import { Button } from 'modules/ui';
import { Container, Page } from 'modules/layout';
import { useSignInMutation } from '../../services';
import * as localforage from 'localforage';

const initialValues = {
  username: '',
  password: '',
};

function LoginPage() {
  const navigate = useNavigate();
  const [signIn] = useSignInMutation();
  const { values, errors, handleChange, ...formik } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      username: Yup.string().required('Required'),
      password: Yup.string().required('Required'),
    }),
    onSubmit: handleSubmit,
  });

  async function handleSubmit(values: typeof initialValues) {
    try {
      const user = await signIn(values).unwrap();
      localforage.setItem('user', user);
      navigate(user?.onboardingFinished ? '/home' : '/onboarding');
    } catch (e) {
      alert('Invalid credentials');
    }
  }

  return (
    <Page>
      <Container className="flex items-center flex-1">
        <form onSubmit={formik.handleSubmit} className="w-full">
          <h1 className="font-['AdineuePRO'] font-bold uppercase text-2xl tracking-[1px] text-center">
            LOG IN
          </h1>
          <TextField
            className={'mt-10'}
            label="Username"
            name="username"
            onChange={handleChange}
            value={values.username}
            required
          />
          <TextField
            className={'mt-4'}
            type="password"
            label="Password"
            name="password"
            onChange={handleChange}
            value={values.password}
            required
          />
          <Button type="submit" className="mt-14">
            Log in
          </Button>
        </form>
      </Container>
    </Page>
  );
}

export default LoginPage;
