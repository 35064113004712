import { Chat } from 'modules/ui';
import { useDevicesChat } from '../../hooks';
import { useLogMessages } from 'modules/logger/hooks/useLogMessages';

export function DevicesChat() {
  const { messages, handleSendMessage } = useDevicesChat();

  useLogMessages('devices', messages);

  return <Chat messages={messages} onSendMessage={handleSendMessage} />;
}
