import { createSlice } from '@reduxjs/toolkit';

export interface InitialState {
  fullscreenLoadingMessage: string | null;
}

const initialState: InitialState = {
  fullscreenLoadingMessage: null,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.fullscreenLoadingMessage = action.payload;
    },
  },
});
export const { setLoading } = uiSlice.actions;
export default uiSlice.reducer;
