interface DeviceOption {
  src: string;
  value: string;
}

export const devices: DeviceOption[] = [
  {
    src: 'apple-health.webp',
    value: 'apple',
  },
  {
    src: 'whoop.webp',
    value: 'whoop',
  },
  {
    src: 'garmin.webp',
    value: 'garmin',
  },
  {
    src: 'google-fit.webp',
    value: 'google',
  },
  {
    src: 'fitbit.webp',
    value: 'fitbit',
  },
  {
    src: 'oura.webp',
    value: 'oura',
  },
  {
    src: 'withings.webp',
    value: 'withings',
  },
  {
    src: 'wahoo.webp',
    value: 'wahoo',
  },
  {
    src: 'peloton.webp',
    value: 'peloton',
  },
  {
    src: 'zwift.webp',
    value: 'zwift',
  },
  {
    src: 'trainingpeaks.webp',
    value: 'trainingpeaks',
  },
  {
    src: 'polar.webp',
    value: 'polar',
  },
  {
    src: 'suunto.webp',
    value: 'suunto',
  },
  {
    src: 'fatsecret.webp',
    value: 'fatsecret',
  },
  {
    src: 'freestylelibre.webp',
    value: 'freestylelibre',
  },
  {
    src: 'eightsleep.webp',
    value: 'eightsleep',
  },
  {
    src: 'samsung.webp',
    value: 'samsung',
  },
  {
    src: 'ifit.webp',
    value: 'ifit',
  },
  {
    src: 'tempo.webp',
    value: 'tempo',
  },
  {
    src: 'concept2.webp',
    value: 'concept',
  },
  {
    src: 'cronometer.webp',
    value: 'cronometer',
  },
  {
    src: 'nutracheck.webp',
    value: 'nutracheck',
  },
  {
    src: 'underarmour.webp',
    value: 'underarmour',
  },
  {
    src: 'renpho.webp',
    value: 'renpho',
  },
  {
    src: 'omron.webp',
    value: 'omron',
  },
  {
    src: 'coros.webp',
    value: 'coros',
  },
  {
    src: 'huawei.webp',
    value: 'huawei',
  },
  {
    src: 'biostrap.webp',
    value: 'biostrap',
  },
  {
    src: 'ridewithgps.webp',
    value: 'ridewithgps',
  },
  {
    src: 'finalsurge.webp',
    value: 'finalsurge',
  },
  {
    src: 'hammerhead.webp',
    value: 'hammerhead',
  },
  {
    src: 'komoot.webp',
    value: 'komoot',
  },
  {
    src: 'rouvy.webp',
    value: 'rouvy',
  },
  {
    src: 'trainerroad.webp',
    value: 'trainerroad',
  },
  {
    src: 'xoss.webp',
    value: 'xoss',
  },
  {
    src: 'xert.webp',
    value: 'xert',
  },
  {
    src: 'brytonsport.webp',
    value: 'brytonsport',
  },
  {
    src: 'technogym.webp',
    value: 'technogym',
  },
  {
    src: 'pul.webp',
    value: 'pul',
  },
];
