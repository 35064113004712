import { TextField, Checkbox } from 'modules/form';
import { devices } from '../../../../constants';
import { ChangeEvent, MouseEvent, useMemo, useState } from 'react';
import { Button } from 'modules/ui';
import { useNavigate } from 'react-router-dom';
import { useAddDevicesMutation } from 'modules/onboarding';

function DevicesList() {
  const navigate = useNavigate();
  const [addDevices] = useAddDevicesMutation();
  const [search, setSearch] = useState('');
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);

  const filteredDevices = useMemo(() => {
    if (!search) {
      return devices;
    }
    return devices.filter((device) =>
      device.value.toLowerCase().includes(search.toLowerCase())
    );
  }, [search]);

  function handleSearchChange({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) {
    setSearch(value);
  }

  function toggleDevice(value: string) {
    return function (e: MouseEvent<HTMLButtonElement>) {
      e.preventDefault();
      setSelectedDevices((prev) => {
        if (prev.includes(value)) {
          return prev.filter((item) => item !== value);
        }
        return [...prev, value];
      });
    };
  }

  async function handleSaveDevices() {
    try {
      await addDevices(
        selectedDevices.map((device) => ({
          name: device,
          data: {},
        }))
      ).unwrap();
      navigate('/onboarding/summary');
    } catch (e) {}
  }

  return (
    <div className="w-full h-full flex flex-col">
      <TextField
        label="Search"
        size="small"
        value={search}
        onChange={handleSearchChange}
      />
      <div className="overflow-y-auto flex-1 basis-0 mt-4">
        {filteredDevices.map((device) => (
          <button
            key={device.value}
            className="w-full px-4 pr-6 h-16 flex items-center justify-between"
            onClick={toggleDevice(device.value)}
          >
            <img
              className="w-full max-w-[100px]"
              src={`/assets/devices/${device.src}`}
              alt={device.value}
            />
            <Checkbox
              className="pointer-events-none"
              checked={selectedDevices.includes(device.value)}
              readOnly
            />
          </button>
        ))}
        {filteredDevices.length === 0 && (
          <div className="w-full h-full flex items-center justify-center">
            <p className="text-sm text-gray-500">No devices found</p>
          </div>
        )}
      </div>
      <div className="flex w-full mt-4">
        <Button onClick={handleSaveDevices} className="w-full">
          Next
        </Button>
      </div>
    </div>
  );
}

export { DevicesList };
