import React, { ButtonHTMLAttributes } from 'react';
import styles from './Button.module.scss';
import cn from 'classnames';
import Icon from '../Icon';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  variant?: 'contained' | 'outlined' | 'text';
}

const Button = ({
  children,
  onClick,
  className,
  variant,
  ...rest
}: ButtonProps) => {
  if (variant === 'text')
    return (
      <button onClick={onClick} className={cn(className)} {...rest}>
        <span className="uppercase text-sm font-bold tracking-[2px] text-black underline">
          {children}
        </span>
      </button>
    );
  return (
    <button onClick={onClick} className={cn(className, styles.root)} {...rest}>
      <span className="uppercase text-sm font-bold tracking-[2px]">
        {children}
      </span>
      <Icon name="arrow-right-long" />
    </button>
  );
};

export default Button;
