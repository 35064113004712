import { Page, Container } from 'modules/layout';
import { Button } from 'modules/ui';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function DisclaimerPage() {
  const navigate = useNavigate();

  function handleClick() {
    localStorage.setItem('disclaimerConfirmed', 'true');
    navigate('/auth');
  }

  useEffect(
    function init() {
      if (localStorage.getItem('disclaimerConfirmed')) {
        navigate('/auth');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Page>
      <div className="h-full w-full flex items-center">
        <Container>
          <h1 className="font-['AdineuePRO'] font-bold uppercase text-2xl tracking-[1px]">
            Disclaimer
          </h1>
          <p className="mt-5">
            This is an application developed for demonstration purposes only,
            and is not using real data for the trainings. By using the app you
            acknowledge that any data or conversation entered can be recorded in
            the app and even seen by a real person for analytical purposes.
            <br />
            <br />
            The performance of the application is dependent on OpenAI, and
            inconsistent results may occur. The demo is using GPT 3.5-turbo.
          </p>
          <Button className="w-full mt-10" onClick={handleClick}>
            I Understand and it's fine
          </Button>
        </Container>
      </div>
    </Page>
  );
}

export default DisclaimerPage;
