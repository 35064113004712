import React, { useEffect, useMemo, useState } from 'react';
import { TextField } from 'modules/form';
import Icon from '../Icon';
import MessageItem from './MessageItem';
import { Message } from 'types';

interface ChatProps {
  messages: Message[];
  onSendMessage(message: Message): void;
}

function Chat({ messages, onSendMessage }: ChatProps) {
  const [message, setMessage] = useState('');
  const scrollTargetRef = React.useRef<HTMLDivElement>(null);

  const filteredMessages = useMemo(() => {
    return messages.filter((message) => !message.hidden);
  }, [messages]);

  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setMessage(e.target.value);
  }

  const handleSendMessage = async () => {
    if (!message.trim()) return;
    onSendMessage({ content: message.trim(), role: 'user', hidden: false });
    setMessage('');
  };

  useEffect(() => {
    if (messages.length > 0 && scrollTargetRef.current) {
      scrollTargetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <div className="overflow-hidden w-full h-full flex flex-col">
      <div className="p-2 overflow-y-auto flex-1 basis-0 mb-4 border border-black">
        {filteredMessages.map((message, index) => (
          <MessageItem className="mb-4 last:mb-0" key={index} data={message} />
        ))}
        <div ref={scrollTargetRef} />
      </div>
      <div className="relative w-full flex items-center">
        <TextField
          inputClassName="pr-10"
          placeholder="Message"
          size="small"
          textarea
          value={message}
          onChange={handleChange}
        />
        <button
          onClick={handleSendMessage}
          className="absolute right-2 bottom-0 flex p-1.5"
        >
          <Icon name="arrow-right-long" color="black" />
        </button>
      </div>
    </div>
  );
}

export default Chat;
