import { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import cn from 'classnames';

interface DotProps {
  delay: number;
}

const Dot = ({ delay }: DotProps) => {
  const controls = useAnimation();

  useEffect(() => {
    const sequence = async () => {
      try {
        await controls.start({
          opacity: 1,
          transition: { duration: 0.3, delay },
        });
        await controls.start({ opacity: 0, transition: { duration: 0.3 } });
      } catch (e) {}
    };

    const intervalId = setInterval(sequence, 1200);
    sequence();

    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <motion.div
      className="w-1 h-1 bg-white mx-1"
      initial={{ opacity: 0 }}
      animate={controls}
    />
  );
};

const TripleDotsLoader = ({ className }: { className?: string }) => (
  <div className={cn(className, 'flex justify-center items-center')}>
    <Dot delay={0} />
    <Dot delay={0.3} />
    <Dot delay={0.6} />
  </div>
);

export default TripleDotsLoader;
