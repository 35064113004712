import { Message } from 'types';

export const injectVariablesToPrompts = (
  messages: Message[],
  variables: Record<string, string | number>
) => {
  const fields = Object.keys(variables);

  return messages.map((message) => {
    return fields.reduce((acc, key) => {
      return {
        ...acc,
        content: acc.content.replace(`{{${key}}}`, variables[key].toString()),
      };
    }, message);
  });
};

export function extractJSONFromString(
  input: string
): Record<string, any> | null {
  const jsonStringRegex = /{(?:[^{}]|{[^{}]*})*}/g;
  const match = input.match(jsonStringRegex);

  if (match) {
    try {
      return JSON.parse(match[0]);
    } catch (error) {
      alert('Unable to parse JSON, try again:' + error);
      return null;
    }
  }

  return null;
}
