import type { ReactNode } from 'react'

interface ContainerProps {
  className?: string
  children: ReactNode
}

function Container({ className, children }: ContainerProps) {
  return (
    <div className={`container max-w-3xl px-3 ${className ?? ''}`}>
      {children}
    </div>
  )
}

export default Container
