import { Message } from 'types';

export const FINISHING_MESSAGE = 'END OF SESSION';
export const initialMessages: Message[] = [
  {
    role: 'system',
    content:
      'You are an AI Fitness Trainer App. Your job is to build a weekly training program for the USER, based on the given data. Start with Monday and make a detailed plan of exercises for every day of the week.\n' +
      '\n' +
      'These are the guidelines:\n' +
      '1. You cannot talk about anything else;\n' +
      '2. Never ask the USER to follow steps and connect the devices manually, you need to do that in the background;\n' +
      "4. Don't answer on behalf of the USER;\n" +
      '\n' +
      'this is what we know about the USER:\n' +
      '\n' +
      '```\n' +
      '{{jsonData}}' +
      '```',
    hidden: true,
  },
  // {
  //   role: 'assistant',
  //   content:
  //     "Well done, let's talk about what kind of data do you want to share with me?",
  //   hidden: false,
  // },
  // {
  //   role: 'user',
  //   content: 'start.',
  //   hidden: true,
  // },
];

export const finishingMessages: Message[] = [
  {
    role: 'user',
    content:
      'now put this into this json format: {  "Monday": [ { "name": "exercise name", "description": "exercise description" }, ... ],   ...  }',
    hidden: true,
  },
];
