import type { ReactNode } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Redux } from '../modules/redux';
import { Authenticated } from 'modules/auth/components/Authenticated';
import { OpenAI } from 'modules/openai';

interface CoreProps {
  children: ReactNode;
}

function Core({ children }: CoreProps) {
  return (
    <Redux>
      <OpenAI>
        <Router>
          <Authenticated>{children}</Authenticated>
        </Router>
      </OpenAI>
    </Redux>
  );
}

export default Core;
