import { useEffect, useRef } from 'react';
import { Message } from 'types';
import { useAppDispatch, useAppSelector } from 'modules/core';
import { useNavigate } from 'react-router-dom';
import { initialMessages } from '../constants';
import {
  useOpenAI,
  useInjectedMessages,
  extractJSONFromString,
} from 'modules/openai';
import { selectUser, useUpdateUserMutation } from 'modules/user';
import { setLoading } from '../../../../../../ui/services';

export function useGoalsChat() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const openai = useOpenAI();
  const [updateUser] = useUpdateUserMutation();
  const user = useAppSelector(selectUser);
  const [messages, setMessages] = useInjectedMessages(initialMessages, {
    name: user.name,
  });
  const initialRequestSent = useRef(false);

  const handleSendMessage = (message: Message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  useEffect(
    function manageChat() {
      async function run() {
        if (
          !openai ||
          messages.length === 0 ||
          (messages.at(-1)?.role === 'assistant' && initialRequestSent.current)
        )
          return;

        if (messages.at(-1)?.content === 'next') {
          navigate('/onboarding/chat/training-program');
          return;
        }

        initialRequestSent.current = true;

        const completion = await openai.createChatCompletion({
          model: 'gpt-3.5-turbo',
          temperature: 0.05,
          max_tokens: 600,
          messages: messages.map(({ hidden, ...message }) => message),
        });
        const responseMessage = completion.data.choices[0].message as Message;
        const finishingMessage = extractJSONFromString(responseMessage.content);
        //.includes(FINISHING_MESSAGE);

        setMessages((prevMessages) => [
          ...prevMessages,
          { ...responseMessage, hidden: !!finishingMessage },
        ]);

        if (!finishingMessage) return;

        dispatch(setLoading('Creating your training program'));

        try {
          await updateUser({
            ...finishingMessage,
          }).unwrap();
          navigate('/onboarding/chat/training-program');
        } catch (e) {
          alert('Error while updating user');
          dispatch(setLoading(null));
        }
      }

      run();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openai, messages]
  );

  return { messages, handleSendMessage } as const;
}
