import cn from 'classnames'
import { ChangeEvent, useEffect, useRef } from 'react'

interface TextFieldProps {
  label?: string
  placeholder?: string
  className?: string
  inputClassName?: string
  error?: boolean
  helperText?: string
  required?: boolean
  size?: 'small' | 'medium'
  textarea?: boolean
  name?: string
  value?: string
  type?: string
  onChange?(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void
}
const TextField = (props: TextFieldProps) => {
  const {
    label,
    placeholder,
    className = '',
    inputClassName = '',
    error = false,
    helperText = '',
    required = false,
    size = 'medium',
    textarea = false,
    type = 'text',
    ...rest
  } = props
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const sharedProps = {
    id: rest.name,
    'aria-label': label,
    placeholder,
    className: cn(
      inputClassName,
      'w-full block border rounded-none px-[15px] leading-[22px] text-black focus:outline-none focus:border-black focus:ring-0',
      {
        'py-2': size === 'small',
        'py-[15px]': size === 'medium',
        'border-bottom-red-500': error,
        'border-black': rest.value,
        'border-[#767677]': !rest.value,
        'resize-none max-h-[200px]': textarea,
      }
    ),
  }

  useEffect(() => {
    if (textareaRef.current && textarea) {
      textareaRef.current.style.height = 'auto'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
      textareaRef.current.style.overflowY =
        textareaRef.current.scrollHeight > 200 ? 'auto' : 'hidden'
    }
  }, [rest.value, textarea])

  return (
    <div className={cn('w-full relative flex flex-col group', className)}>
      {label && (
        <label
          htmlFor={rest.name}
          className={cn(
            'absolute -translate-y-1/2 text-[#767677] pointer-events-none transition-all duration-300 ease-[cubic-bezier(0.3,0,0,1)]',
            {
              'top-0 left-[10px] bg-white px-[5px] text-sm': rest.value,
              'top-1/2 text-base left-[15px]': !rest.value,
              'group-focus-within:top-0 group-focus-within:text-sm group-focus-within:bg-white group-focus-within:px-[5px] group-focus-within:left-[10px]':
                true,
            }
          )}
        >
          {label}
          {required && ` *`}
        </label>
      )}
      {textarea ? (
        <textarea ref={textareaRef} rows={1} {...rest} {...sharedProps} />
      ) : (
        <input type={type} {...rest} {...sharedProps} />
      )}
      {helperText && (
        <p
          className={cn('text-sm mt-1', {
            'text-red-500': error,
            'text-gray-500': !error,
          })}
        >
          {helperText}
        </p>
      )}
    </div>
  )
}

export default TextField
