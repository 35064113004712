import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + 'auth/',
  }),
  endpoints: (build) => ({
    signIn: build.mutation({
      // note: an optional `queryFn` may be used in place of `query`
      query: (body) => ({
        url: `signin`,
        method: 'POST',
        body: body,
      }),
    }),
  }),
});

export const { useSignInMutation } = authApi;
