import { Message } from 'types';
import { useEffect } from 'react';
import { useLogMessagesMutation } from '../services/loggerApi';
import { usePrevious } from 'hooks';
import { findArrayDifference } from '../../../utils';

export function useLogMessages(chatName: string, messages: Message[]) {
  const [logMessages] = useLogMessagesMutation();
  const prevMessages = usePrevious(messages);

  useEffect(
    function log() {
      messages.length !== 0 &&
        logMessages(
          findArrayDifference(messages, prevMessages || []).map((message) => ({
            ...message,
            chatName,
          }))
        );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [messages]
  );
}
