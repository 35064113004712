import { Chat } from 'modules/ui';
import { usePersonalInfoChat } from '../../hooks';
import { useLogMessages } from '../../../../../../../logger/hooks/useLogMessages';

export default function PersonalInfoChat() {
  const { messages, handleSendMessage } = usePersonalInfoChat();

  useLogMessages('personal-info', messages);

  return <Chat messages={messages} onSendMessage={handleSendMessage} />;
}
