import { Container, Page } from 'modules/layout';
import { Button, Title } from 'modules/ui';
import { DayPlan } from '../../../../components';
import { Link } from 'react-router-dom';
import { daysOfWeek } from '../../../../constants';

function WeeklyProgramPage() {
  return (
    <Page>
      <div className="w-full flex-1 flex flex-col">
        <Container>
          <div className="w-full flex items-center justify-between">
            <Title>Your weekly program</Title>
          </div>
        </Container>
        <div className="mt-4 flex-1 basis-0 overflow-y-auto">
          <Container className="w-full">
            {daysOfWeek.map((dayOfWeek) => (
              <DayPlan
                key={dayOfWeek}
                className="mb-4 last:mb-0"
                dayOfWeek={dayOfWeek}
              />
            ))}
          </Container>
        </div>
      </div>
      <div className="w-full">
        <Container className="flex pt-4">
          <Link className="w-full" to={'/home'}>
            <Button>Home</Button>
          </Link>
          <Link className="w-full ml-4" to={'/update-training-program'}>
            <Button>Change</Button>
          </Link>
        </Container>
      </div>
    </Page>
  );
}

export default WeeklyProgramPage;
