import { Chat } from 'modules/ui';
import { useShareDataChat } from '../../hooks';
import { useLogMessages } from 'modules/logger/hooks/useLogMessages';

export default function ShareDataChat() {
  const { messages, handleSendMessage } = useShareDataChat();
  useLogMessages('share-data', messages);

  return <Chat messages={messages} onSendMessage={handleSendMessage} />;
}
