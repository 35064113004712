import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'modules/core';
import { authApi } from '../../auth';

export interface User {
  access_token: string | null;
  id?: string;
  name: string;
  username: string;
  age: number | null;
  gender: string | null;
  weight: number | null;
  height: number | null;
  homeLocation: string | null;
  trainingGoal: string | null;
  personalRequests: string[];
  createdAt?: string;
  updatedAt?: string;
}

const initialState: User = {
  access_token: null,
  name: '',
  username: '',
  age: null,
  gender: null,
  weight: null,
  height: null,
  homeLocation: null,
  trainingGoal: null,
  personalRequests: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => action.payload,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.signIn.matchFulfilled,
      (_, action) => action.payload
    );
  },
});
export const { setUser } = userSlice.actions;

export const selectUser = (state: RootState) => state.user;
export default userSlice.reducer;
