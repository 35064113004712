import { ReactNode, useEffect, useState } from 'react';
import localforage from 'localforage';
import { useAppDispatch } from 'modules/core';
import { setUser } from 'modules/user';
import { useLocation, useNavigate } from 'react-router-dom';

interface AuthenticatedProps {
  children: ReactNode;
}

function Authenticated({ children }: AuthenticatedProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [authState, setAuthState] = useState<
    'loading' | 'anonymous' | 'authenticated'
  >('loading');

  useEffect(function init() {
    localforage.getItem('user').then((user) => {
      if (user) {
        dispatch(setUser(user));
        setAuthState('authenticated');
        navigate(pathname === '/' ? '/home' : pathname);
      } else {
        setAuthState('anonymous');
        navigate('/');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (authState === 'loading') return <p>Loading...</p>;

  return <>{children}</>;
}

export default Authenticated;
