import type { ReactNode } from 'react';

interface TitleProps {
  children: ReactNode;
}

function Title({ children }: TitleProps) {
  return (
    <h2 className="font-['AdineuePRO'] font-bold uppercase text-2xl tracking-[1px]">
      {children}
    </h2>
  );
}

export default Title;
