import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAccessToken } from 'modules/auth';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: async (headers) => {
      const token = await getAccessToken();
      if (token) headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    updateUser: build.mutation({
      query: (body) => ({
        url: `users`,
        method: 'PUT',
        body,
      }),
    }),
    getUser: build.query({
      query: () => 'auth/me',
    }),
  }),
});

export const { useUpdateUserMutation, useGetUserQuery } = userApi;
