import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAccessToken } from 'modules/auth';

export const loggerApi = createApi({
  reducerPath: 'loggerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: async (headers) => {
      const token = await getAccessToken();
      if (token) headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    logMessages: build.mutation({
      query: (body) => ({
        url: `messages`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useLogMessagesMutation } = loggerApi;
