import React, { ChangeEvent, HTMLProps } from 'react';
import { Icon } from 'modules/ui';
import cn from 'classnames';

interface CheckboxProps
  extends Omit<HTMLProps<HTMLInputElement>, 'type' | 'onChange'> {
  label?: string;
  checked: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = ({
  className,
  label,
  checked,
  onChange,
  ...rest
}: CheckboxProps): JSX.Element => {
  return (
    <label className={cn(className, 'flex items-center cursor-pointer')}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className={cn('sr-only')}
        {...rest}
      />
      <span
        className={`w-6 h-6 border-2 relative ${
          checked ? 'bg-black border-black' : 'border-[#767677]'
        }`}
      >
        {checked && (
          <Icon
            className="absolute -top-[2px] -left-[1px] scale-90 flex"
            name="checkbox-checkmark"
          />
        )}
      </span>
      {label && <span className="ml-4 text-gray-700">{label}</span>}
    </label>
  );
};

export default Checkbox;
