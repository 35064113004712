import { useEffect } from 'react';
import { Message } from 'types';
import { useAppDispatch, useAppSelector } from 'modules/core';
import { useNavigate } from 'react-router-dom';
import { useAddDevicesMutation } from 'modules/onboarding/modules';
import {
  FINISHING_MESSAGE,
  finishingOnboardingDevicesMessages,
  initialOnboardingDevicesMessages,
} from '../constants';
import { setDevices } from 'modules/onboarding';
import {
  useOpenAI,
  useInjectedMessages,
  extractJSONFromString,
} from 'modules/openai';
import { selectUser } from 'modules/user';
import { setLoading } from '../../../../../../ui/services';

export function useDevicesChat() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const openai = useOpenAI();
  const [addDevices] = useAddDevicesMutation();
  const user = useAppSelector(selectUser);
  const [messages, setMessages] = useInjectedMessages(
    initialOnboardingDevicesMessages,
    {
      name: user.name,
    }
  );

  const handleSendMessage = (message: Message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  useEffect(
    function manageChat() {
      async function run() {
        if (
          !openai ||
          messages.length === 0 ||
          messages.at(-1)?.role === 'assistant'
        )
          return;

        const completion = await openai.createChatCompletion({
          model: 'gpt-3.5-turbo',
          temperature: 0.1,
          messages: messages.map(({ hidden, ...message }) => message),
        });
        const responseMessage = completion.data.choices[0].message as Message;
        const hasFinishingMessage =
          responseMessage.content.includes(FINISHING_MESSAGE);

        setMessages((prevMessages) => [
          ...prevMessages,
          { ...responseMessage, hidden: hasFinishingMessage },
        ]);

        if (!hasFinishingMessage) return;

        dispatch(setLoading('Adding devices'));

        const finishingCompletion = await openai?.createChatCompletion({
          model: 'gpt-3.5-turbo',
          temperature: 0.1,
          messages: [
            ...messages.map(({ hidden, ...message }) => message),
            responseMessage,
            ...finishingOnboardingDevicesMessages.map(
              ({ hidden, ...message }) => message
            ),
          ],
        });

        const result = extractJSONFromString(
          finishingCompletion?.data.choices[0].message?.content || ''
        );

        if (!result?.devices) {
          alert('Error adding devices. Refresh the page and try again.');
          dispatch(setLoading(null));
          return;
        }

        dispatch(setDevices(result?.devices));
        try {
          await addDevices(
            result?.devices.map((device: string) => ({
              name: device,
              data: {},
            }))
          );
          navigate('/onboarding/chat/share-data');
          dispatch(setLoading(null));
        } catch (error) {
          alert('Error adding devices');
          dispatch(setLoading(null));
        }
      }

      run();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openai, messages]
  );

  return { messages, handleSendMessage } as const;
}
