import React from 'react';
import cn from 'classnames';

interface IconProps {
  className?: string;
  name: string;
  color?: string;
}

function Icon({ name, className, color = 'white' }: IconProps) {
  return (
    <span className={cn(className)} style={{ color }} role="img">
      <svg className={`inline-block w-6 h-6 pointer-events-none`}>
        <use xlinkHref={'#' + name}></use>
        <title></title>
      </svg>
    </span>
  );
}

export default Icon;
