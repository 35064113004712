import { Chat } from 'modules/ui';
import { useTrainingProgramChat } from '../../hooks';
import { useLogMessages } from 'modules/logger/hooks/useLogMessages';

export default function TrainingProgramChat() {
  const { messages, handleSendMessage } = useTrainingProgramChat();

  useLogMessages('training-program', messages);

  return <Chat messages={messages} onSendMessage={handleSendMessage} />;
}
